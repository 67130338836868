import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResultCard from "../measurement/ResultCard";
import {useEffect, useRef, useState} from "react";
import ResultData from "../../utilities/types/ResultData";
import ChestIcon from "../../images/icons/measurement/chest_icon.svg"
import ChestActiveIcon from "../../images/icons/measurement/chest_active_icon.svg"
import underChestIcon from "../../images/icons/measurement/chest_icon.svg"
import underChestActiveIcon from "../../images/icons/measurement/under_chest_active_icon.svg"
import ShoulderIcon from "../../images/icons/measurement/shoulder_icon.svg"
import ShoulderActiveIcon from "../../images/icons/measurement/shoulder_active_icon.svg"
import KneeIcon from "../../images/icons/measurement/knee_icon.svg"
import KneeActiveIcon from "../../images/icons/measurement/knee_active_icon.svg"
import HipsIcon from "../../images/icons/measurement/hips_icon.svg"
import HipsActiveIcon from "../../images/icons/measurement/hips_active_icon.svg"
import neckIcon from "../../images/icons/measurement/neck_icon.svg"
import neckActiveIcon from "../../images/icons/measurement/neck_active_icon.svg"
import ArmIcon from "../../images/icons/measurement/hand_icon.svg"
import ArmActiveIcon from "../../images/icons/measurement/hand_active_icon.svg"
import UpperBodyIcon from "../../images/icons/measurement/upper_body_icon.svg"
import UpperActiveBodyIcon from "../../images/icons/measurement/upper_body_active_icon.svg"
import LowerBodyIcon from "../../images/icons/measurement/lower_body_icon.svg"
import LowerBodyActiveIcon from "../../images/icons/measurement/lower_body_active_icon.svg"
import WristActiveIcon from "../../images/icons/measurement/wrist_active_icon.svg"
import WristIcon from "../../images/icons/measurement/wrist_icon.svg"
import BackBody from "../bodyShape/BackBody";
import FullBody from "../bodyShape/FullBody";
// @ts-ignore
import anime from 'animejs';

interface Props {
    data:any,
}

const ResultCard2 = ({data}:Props) => {

    const defaultResults: Array<ResultData> = [
        {
            title: 'Neck',
            value: data.Neck,
            type: "NECK",
            imageType: "FRONT",
            icon: <img alt="" src={neckIcon}/>,
            activeIcon: <img alt="" src={neckActiveIcon}/>
        },
        {
            title: 'Shoulders',
            value: data.Shoulder,
            type: "SHOULDER",
            imageType: "FRONT",
            icon: <img alt="" src={ShoulderIcon}/>,
            activeIcon: <img alt="" src={ShoulderActiveIcon}/>
        },
        {
            title: 'Chest',
            value: data.Chest,
            type: "CHEST",
            imageType: "FRONT",
            icon: <img alt="" src={ChestIcon}/>,
            activeIcon: <img alt="" src={ChestActiveIcon}/>
        },
        {
            title: 'Under chest',
            value: data.UnderChest,
            type: "UNDER_CHEST",
            imageType: "FRONT",
            icon: <img alt="" src={underChestIcon}/>,
            activeIcon: <img alt="" src={underChestActiveIcon}/>
        },
        {
            title: 'Arm length',
            value: data.ArmLength,
            type: "ARM_LENGTH",
            imageType: "FRONT",
            icon: <img alt="" src={ArmIcon}/>,
            activeIcon: <img alt="" src={ArmActiveIcon}/>
        },
        {
            title: 'Waist',
            value: data.Waist,
            type: "WAIST",
            imageType: "FRONT",
            icon: <img alt="" src={ChestIcon}/>,
            activeIcon: <img alt="" src={ChestActiveIcon}/>
        },
        {
            title: 'Wrist',
            value: data.Wrist,
            type: "WRIST",
            imageType: "FRONT",
            icon: <img alt="" src={WristIcon}/>,
            activeIcon: <img alt="" src={WristActiveIcon}/>
        },
        {
            title: 'Upper body ',
            value: data.UpperBody,
            type: "UPPER_BODY",
            imageType: "FRONT",
            icon: <img alt="" src={UpperBodyIcon}/>,
            activeIcon: <img alt="" src={UpperActiveBodyIcon}/>
        },
        {
            title: 'Hips',
            value: data.Hips,
            type: "HIPS",
            imageType: "BACK",
            icon: <img alt="" src={HipsIcon}/>,
            activeIcon: <img alt="" src={HipsActiveIcon}/>
        },
        {
            title: 'Knee',
            value: data.Knee,
            type: "KNEE",
            imageType: "BACK",
            icon: <img alt="" src={KneeIcon}/>,
            activeIcon: <img alt="" src={KneeActiveIcon}/>
        },
        {
            title: 'Lower body ',
            value: data.LowerBody,
            type: "LOWER_BODY",
            imageType: "BACK",
            icon: <img alt="" src={LowerBodyIcon}/>,
            activeIcon: <img alt="" src={LowerBodyActiveIcon}/>
        },

    ];
    const [results, setResults] = useState<Array<ResultData>>(defaultResults);
    const [currentBodyShape, setCurrentBodyShape] = useState<ResultData | null>(defaultResults[0])
    const [activeResultIndex, setActiveResultIndex] = useState<number>(0);
    const circleRef1 = useRef<HTMLDivElement>(null)
    const circleRef2 = useRef<HTMLDivElement>(null)
    const circleRef3 = useRef<HTMLDivElement>(null)
    useEffect(() => {
        anime({
            targets: circleRef1?.current,
            offset: [0, 1],
            opacity: [1, 0.8],
            easing: 'easeInOutQuad', // Easing function
            duration: 1000,
        })
        anime({
            targets: circleRef2?.current,
            offset: [0, 1],
            opacity: [1, 0.8],
            easing: 'easeInOutQuad', // Easing function
            duration: 1000,
        })
        anime({
            targets: circleRef3?.current,
            offset: [0, 1],
            opacity: [1, 0.8],
            easing: 'easeInOutQuad', // Easing function
            duration: 1000,
        })
    }, [currentBodyShape])
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3.3,

                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]


    };
    const handleSelectCard = (index: number, data: ResultData) => {
        setActiveResultIndex(index);
        setCurrentBodyShape(data)


    }
    return <div>

        <div className="w-full relative mt-[10px]">
            <div ref={circleRef1}
                 className="w-[350px] transform -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2 h-[350px] border-blue-100 border rounded-full absolute z-30 opacity-60"></div>
            <div ref={circleRef2}
                 className="w-[150px] h-[150px] -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2 border border-blue-100 rounded-full absolute z-30 opacity-60"></div>
            <div ref={circleRef3}
                 className="w-[250px] h-[250px] -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2 border border-blue-100 rounded-full absolute z-30 opacity-60"></div>
            <div className={`flex justify-center relative result-image-wrapper mt-16 `}>
                {/*   {currentBodyShape && <FullBody type={currentBodyShape.type}/>}*/}
                {currentBodyShape && currentBodyShape.imageType === "BACK" && <BackBody type={currentBodyShape.type}/>}
                {currentBodyShape && currentBodyShape.imageType === "FRONT" && <FullBody type={currentBodyShape.type}/>}
            </div>
        </div>
        <div className="w-11/12 relative slider-wrapper pt-3 z-50  -mt-10 mx-auto">
            <Slider  {...settings}>
                {results.map((result, index: number) => {
                    return <div key={'slide-' + index}>
                        <ResultCard onClick={() => handleSelectCard(index, result)}
                                    isActive={index === activeResultIndex} resultData={result}/>
                    </div>
                })}
            </Slider>
        </div>
    </div>
}
export default ResultCard2;