import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './resources/en/en.json';
import faTranslation from './resources/fa/fa.json';


i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslation
        },
        fa: {
            translation: faTranslation,

        }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18next;