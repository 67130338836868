import React from "react";

import {DirectionType} from "antd/es/config-provider";
interface appContextInterFace  {
    isLoading:boolean,
    handleUser:Function,
    user:any,
    capturing:boolean,
    step:number,
    handleCapturing:Function
    handleStep:Function
    handleLoading:Function,
    handleDirection:Function,
    direction:DirectionType,
    token:string|null,
    handleLogin:Function,
    handleLogout:Function,
    lang:string,
    handleLang:Function
}
const AppContext = React.createContext<appContextInterFace>({
    isLoading: false,
    token:"",
    lang:"",
    handleLang:()=>{},
    handleLogin:()=>{},
    handleLogout:()=>{},
    handleLoading:()=>{},
    step:0,
    capturing:false,
    handleCapturing:()=>{},
    handleStep:()=>{},
    handleDirection:()=>{},
    handleUser:()=>{},
    direction:'ltr',
    user:null,
});
export default AppContext;