import React, {useContext, useEffect, useState} from "react";
import fullView_1 from "../../../images/guide/full_1.webp";
import fullView_2 from "../../../images/guide/full_2.webp";
import sideView1 from "../../../images/guide/side_1.webp";
import Guide from "../../Guide";
import GetHeight from "../../sdk/GetHeight";
import DataContext from "../../../utilities/contexts/sdk/dataContext";
import ChooseImage from "../../sdk/ChooseImage";
import sizeMeasurementApi from "../../../utilities/Api/sdk/sizeMeasurement"
import SdkContext from "../../../utilities/contexts/sdk/sdkContext";
import ResultCard2 from "../../measurement/ResultCard2";
import {Steps, notification} from "antd";
import Result from "../../sdk/Result";
import SdkApi from "../../../utilities/Api/sdk/Api";
import Loading from "../../Loading";
import {Navigate} from "react-router-dom";

interface step {
    title: string,
    description?: string,
    image: string,
    texts: Array<string>
}

const defaultSteps = {
    GUIDE: "Guide",
    GET_HEIGHT: "GetHeight",
    FRONT_POSE: "FrontPose",
    SIDE_POSE: "SidePose",
    RESULT: "Result"
}
const MeasurementGuid: Array<step> = [
    {
        title: "Stand Straight",
        description: "Before taking a photo, pay attention to the following points",
        image: fullView_1,
        texts: ['Place the camera on the table at 90 degrees', 'Stand straight in front of the camera', 'Capture your complete height']

    },
    {
        title: "Front Pose",
        description: "Before taking a photo, pay attention to the following points",
        image: fullView_2,
        texts: ['Let your hands hang by your sides', 'Don’t wear baggy clothes', 'Don’t  hide your body shape in clothes']

    },
    {
        title: "Side Pose",
        description: "Before taking a photo, pay attention to the following points",
        image: sideView1,
        texts: ["Do not stand at an angle", 'Hang your arms on your thighs’ side']

    },

]
const SizeMeasurement = () => {
    const [step, setStep] = useState(defaultSteps.GUIDE);
    const [height, setHeight] = useState<number | null>(null);
    const [frontPoseImage, setFrontPoseImage] = useState<string | null>(null);
    const [sidePoseImage, setSidePoseImage] = useState<string | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [result, setResult] = useState<any>(null);
    const [api, contextHolder] = notification.useNotification();
    const sdkContext = useContext(SdkContext);
    const [loading, setLoading] = useState(false)
    const [isCapturing, setIsCapturing] = useState(false)
    useEffect(() => {
        if (frontPoseImage) {
            console.log('front ready', frontPoseImage)
            setStep(defaultSteps.SIDE_POSE)
        }
    }, [frontPoseImage])
    useEffect(() => {
        if (sidePoseImage) {
            handleStep(defaultSteps.GET_HEIGHT)
        }
    }, [sidePoseImage]);
    useEffect(() => {
        if (height && frontPoseImage && sidePoseImage) {
            handleSizeMeasurement(frontPoseImage, sidePoseImage, height).then(r => console.log(r));
        }
    }, [height])
    useEffect(() => {
        if (step) {
            switch (step) {
                case defaultSteps.FRONT_POSE: {
                    setFrontPoseImage(null);
                    setSidePoseImage(null)
                    setCurrentIndex(0)
                    break
                }
                case defaultSteps.SIDE_POSE: {
                    setCurrentIndex(1)
                    setSidePoseImage(null)
                    break
                }
                case defaultSteps.GET_HEIGHT: {
                    setCurrentIndex(2)
                    setIsCapturing(false)
                    break
                }
                case defaultSteps.RESULT: {
                    setCurrentIndex(3)
                    break
                }
            }
        }
    }, [step])
    const handleStep = (newStep: string) => {
        setStep(newStep)
    }
    const handleHeight = (newHeight: number | null) => {
        setHeight(newHeight)
    }
    const handleFrontPoseImage = (newImage: string | null) => {
        setFrontPoseImage(newImage);
    }
    const handleSidePoseImage = (newImage: string | null) => {
        setSidePoseImage(newImage)
    }
    const handleSizeMeasurement = async (frontPose: string, sidePose: string, height: number) => {
        const data = {
            "img_full_view_body": frontPose.split(",")[1],
            "img_side_view_body": sidePose.split(",")[1],
            "present_height": height,
        };
        sdkContext.handleLoading(true)
        const response = await sizeMeasurementApi({token: sdkContext.token, data});
        console.log(response, '------------')
        if (response && response.ok) {

            if (sdkContext.callback) {
                setLoading(true);
                SdkApi.post(sdkContext.callback, {...response.data?.result}).then(res => {
                    console.log(res)
                }).finally(() => {
                    setLoading(false)
                })
            }

            setResult(response.data?.result);
            setStep(defaultSteps.RESULT);
            sdkContext.handleLoading(false)
        } else {
            api.error({
                message: response.message,
                placement: "topRight",
            })
            sdkContext.handleLoading(false)
        }
    }
    const handleBack = (newStep: string) => {
        setStep(newStep)
    }
    const handleCapturing = (value: boolean) => {
        setIsCapturing(value)
    }
    const handleTakePhoto = (frontPose: string, sidePose: string) => {

        setFrontPoseImage(frontPose)
        setSidePoseImage(sidePose)
        handleStep(defaultSteps.GET_HEIGHT)

    }
    if (!sdkContext.token) {
        localStorage.clear();
        return <Navigate to={'/sdk/accessDenied'}/>
    }
    return <div>
        {contextHolder}
        <DataContext.Provider value={{
            handleTakePhoto,
            height,
            frontPoseImage,
            sidePoseImage,
            handleHeight,
            handleFrontPoseImage,
            handleSidePoseImage,
            isCapturing,
            handleCapturing,
        }}>
            {!loading && !isCapturing &&
                <div className="w-11/12 p-5 rounded-lg  bg-white  hidden md:block md:w-6/12 mx-auto lg:w-4/12">
                    <Steps current={currentIndex}>
                        <Steps.Step stepIndex={0} title="Front Pose"/>
                        <Steps.Step stepIndex={1} title="Side Pose"/>
                        <Steps.Step stepIndex={2} title="Height"/>
                        <Steps.Step stepIndex={3} title="Result"/>
                    </Steps>
                </div>}
            {step === defaultSteps.GUIDE &&
                <Guide callback={() => handleStep(defaultSteps.FRONT_POSE)} slides={MeasurementGuid}/>}
            {(step === defaultSteps.FRONT_POSE || step === defaultSteps.SIDE_POSE) &&
                <ChooseImage type={step === defaultSteps.FRONT_POSE ? "frontPose" : "sidePose"}/>}
            {step === defaultSteps.GET_HEIGHT &&
                <GetHeight handleBack={() => handleBack(defaultSteps.FRONT_POSE)} hasBack={true}/>}
            {step === defaultSteps.RESULT && result && loading && <Loading message='sending result to platform'/>}
            {step === defaultSteps.RESULT && result && !loading &&
                <Result returnUrl={sdkContext.callback} result={result}/>}
        </DataContext.Provider>

    </div>
}
export default SizeMeasurement;