interface Props{
    className?:string,

}
const ServiceIcon=(props:Props)=> {
    return <svg className={` ${props.className} p-[3px]`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
<path id="category-2036097" d="M16.965,10.791A3.076,3.076,0,0,1,20,13.88h0V16.9A3.086,3.086,0,0,1,16.965,20H14a3.077,3.077,0,0,1-3.025-3.1h0V13.88A3.058,3.058,0,0,1,14,10.791h2.961Zm-12.715,0a.811.811,0,0,1,.779.387.849.849,0,0,1,0,.885.811.811,0,0,1-.779.387H3.035A1.425,1.425,0,0,0,1.624,13.88h0v2.984a1.454,1.454,0,0,0,1.411,1.431H6.014a1.387,1.387,0,0,0,1-.416,1.446,1.446,0,0,0,.414-1.015h0V12.383l-.013-.111a.839.839,0,0,1,.378-.744.8.8,0,0,1,.914.027.843.843,0,0,1,.316.876h0v4.462a3.067,3.067,0,0,1-3.035,3.1H3.035A3.133,3.133,0,0,1,0,16.892H0V13.88a3.121,3.121,0,0,1,.889-2.187,2.994,2.994,0,0,1,2.145-.9H4.251Zm12.715,1.658H14a1.416,1.416,0,0,0-1.4,1.431h0V16.9a1.437,1.437,0,0,0,.408,1.014,1.377,1.377,0,0,0,.994.416h2.961a1.378,1.378,0,0,0,.994-.416,1.437,1.437,0,0,0,.408-1.014h0V13.88a1.446,1.446,0,0,0-.41-1.012,1.387,1.387,0,0,0-.991-.419ZM16.965,0a3.133,3.133,0,0,1,3.025,3.1h0V6.111a3.123,3.123,0,0,1-.86,2.2A3,3,0,0,1,17,9.237H15.777a.836.836,0,0,1,0-1.658h1.188a1.4,1.4,0,0,0,1-.432,1.456,1.456,0,0,0,.4-1.027h0V3.1a1.435,1.435,0,0,0-1.4-1.431H14A1.416,1.416,0,0,0,12.6,3.1h0V7.617l-.01.1a.831.831,0,0,1-.826.711.8.8,0,0,1-.569-.258.834.834,0,0,1-.22-.595h0V3.1a3.122,3.122,0,0,1,.883-2.19A2.994,2.994,0,0,1,14,0h2.961ZM6,0A3.076,3.076,0,0,1,9.03,3.1h0V6.12a3.121,3.121,0,0,1-.889,2.187A2.994,2.994,0,0,1,6,9.209H3.035A3.076,3.076,0,0,1,0,6.12H0V3.1A3.086,3.086,0,0,1,3.035,0H6ZM6,1.667H3.035a1.386,1.386,0,0,0-1.009.409A1.446,1.446,0,0,0,1.606,3.1h0V6.12a1.446,1.446,0,0,0,.41,1.04,1.387,1.387,0,0,0,1.019.419H6a1.4,1.4,0,0,0,1-.432A1.456,1.456,0,0,0,7.4,6.12h0V3.1A1.426,1.426,0,0,0,6,1.667Z" fill="#200e32"/>
</svg>

}
export default ServiceIcon;