import axios from "axios";
import {backUrl} from "../Urls";

const SdkApi =axios.create({
    baseURL: backUrl+"/",
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

    },

})
SdkApi.interceptors.response.use(
    (response) => {

        return response
    },
    (error) => {
        if(error.response.status==401||error.response.status==403){
            console.log(error)
            localStorage.removeItem('sdk_token')
            localStorage.removeItem('callback')
            localStorage.removeItem('serviceType')
            if(window&&window.location){
                window.location.href = '/sdk/accessDenied';

            }




        }

        // Handle error globally
        console.error('Request failed:', error);
        return Promise.reject(error);
    }
);

export default SdkApi;