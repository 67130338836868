import {useState} from "react";
import {Button} from "antd";
import TopTitle from "./TopTitle";

interface GuidProps {
    slides:Array<step>
    callback:Function,
}
interface step{
    title:string,
    description?:string,
    image:string,
    texts:Array<string>
}
const Guide=(props:GuidProps)=>{
    const {slides,callback}=props;
    const [step,setStep]=useState(0);
    const handleNext=()=>{
        if(step+1===slides.length){
            if(callback){
                callback()
            }
        }else {
            setStep(step+1)
        }

    }
    const handlePrev=()=>{
        setStep(step-1)
    }
    return <div className="w-full h-screen pt-[60px] z-50 fixed bottom-0 top-0 left-0 bg-p">

            {slides.map((slide,index)=>{
                    return <div key={index} className={`h-full justify-between pt-10 flex-col items-center ${step===index? 'flex':'hidden'}`}>
                        <div className="w-full flex justify-center flex-col items-center">
                            <TopTitle title={slide.title} disableBack={true}/>
                            <h1 className=" uppercase font-bold text-primary px-10 hidden text-lg lg:block">{slide.title}</h1>
                            <p className="mb-7 px-5 text-center ">{slide.description}</p>
                            <div className="w-full p-3 flex justify-center overflow-hidden">
                                <img className="max-w-[220px] rounded-xl" src={slide.image} alt=""/>
                            </div>
                            <div className="mt-5">
                                <ul className={slide.texts.length>1 ? "list-disc":""}>
                                    {slide.texts.map((text,i)=>{
                                        return  <li className="lowercase" key={i+"text"}>{text}</li>
                                    })}

                                </ul>
                            </div>
                        </div>
                        {step===0 &&    <div className="sticky w-full fixed bottom-0 flex justify-center  px-4 py-3 bg-white bottom-0 shadow">
                            <Button onClick={handleNext} type="primary"  size="large" className="btn-primary md:w-[350px] w-full">NEXT </Button>
                        </div>}
                        {step!==0 &&    <div className="sticky w-full flex justify-center fixed bottom-0 px-4 py-3 bg-white bottom-0 shadow">
                          <div className="w-full md:w-full lg:w-5/12  xl:w-4/12  grid grid-cols-2 ">
                              <Button onClick={handlePrev} type="link"   size="large" className=" w-full">PREVIOUS</Button>
                              <Button onClick={handleNext} type="primary"  size="large" className="btn-primary w-full">NEXT </Button>
                          </div>

                        </div>}
                    </div>
                 })}
                <div>

                </div>
        </div>
}
export default Guide;