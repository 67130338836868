import Logo from "../../../images/logo_white.png"
import {Link, Navigate, useLocation} from "react-router-dom";
import {Button, Divider} from "antd";
import React, {useContext, useState} from "react";
import AppContext from "../../../utilities/contexts/appContext";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "../../../locales/i18n";
const Guest=()=>{
    const { t } = useTranslation();
    const location=useLocation();
    const appContext=useContext(AppContext);


    if(appContext.token){
        return <Navigate  to={`/${i18n.language}/dashboard`}/>
    }
    return <div id="guest" className="flex justify-start pt-[150px] h-screen items-center flex-col p-5 w-full ">
                <div className="w-full flex flex-col justify-center items-center md:w-6/12 lg:w-3/12 mx-auto">
                    <img className="w-[100px] " alt="" src={Logo} />
                    <p className="text-xl text-white font-bold mt-[35px]">{t('guest.welcome')}</p>
                    <Divider plain={true}/>
                    <div className="grid grid-cols-1 w-full gap-3">
                        <Link  state={location.state} className="w-full " to={`/${i18next.language}/login`}><Button size="large" className="w-full btn-white">{t('guest.login')}</Button></Link>
                        <Link className="w-full " to={`/${i18next.language}/guest/body-measurement`}><Button size="large" type="default"  className="w-full  btn-white-outline mt-5">{t('guest.freeTrial')}</Button></Link>
                    </div>

                </div>

            </div>
}
export default Guest;