import React from "react";

interface measurementContextInterface {
    isLoading: boolean,
    currentSound: Howl | null,
    sounds: Array<Howl>,
    hasCameraAccess: boolean,
    cameraErrorMessage: string | null,

    cameraIsReady:boolean,
    handleCameraIsReady:Function,
    handleCameraErrorMessage: Function,
    handleCameraAccess: Function,
    handleLoading: Function,
    handlePlaySound: Function,
}

const MeasurementContext = React.createContext<measurementContextInterface>({
    isLoading: false,
    hasCameraAccess: true,
    cameraErrorMessage: null,
    cameraIsReady:false,
    sounds: [],
    currentSound: null,
    handleCameraErrorMessage: (value: string | null) => {},
    handleLoading: (value: boolean) => {},
    handleCameraIsReady:(value:boolean)=>{},
    handlePlaySound: (index: number) => {},
    handleCameraAccess: (value: boolean) => {},

});
export default MeasurementContext;