import Webcam from "react-webcam";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {AiOutlineLoading3Quarters} from "react-icons/ai"

import AppContext from "../../utilities/contexts/appContext";
import {Button, Spin} from "antd";
import StandMobileDegree from "../StandMobileDegree";
import measurementOption from "../../utilities/types/bodyMeasurementCameraOptions";
import {Howl} from 'howler';
import useDeviceOrientation from "../../utilities/hooks/useDeviceOrientation";
import MeasurementContext from "../../utilities/contexts/measurementContext";
import bodyMeasurementCameraOptions from "../../utilities/types/bodyMeasurementCameraOptions";
import degreeAudioSrc from "../../sounds/90Degrees.wav";
import timerAudioSrc from "../../sounds/Timer.mp3";
import fullViewCorrectAudioSrc from "../../sounds/fullView.wav";
import fullViewCorrectImage from "../../images/measurement/full_side.png";
import sideViewCorrectAudioSrc from "../../sounds/sideView.wav";
import sideViewCorrectImage from "../../images/measurement/half_side.png";
import {useNavigate} from "react-router-dom";
import Loading from "../Loading";
import {TbCapture} from "react-icons/tb";

const FACING_MODE_USER = "user";
const videoConstraints = {
    facingMode: FACING_MODE_USER
};
const frontCameraOptions: bodyMeasurementCameraOptions = {
    title: 'Front Pose',
    degreeAudioSrc: degreeAudioSrc,
    timerAudioSrc: timerAudioSrc,
    correctStandAudioSrc: fullViewCorrectAudioSrc,
    timerCount: 7,
    captureStartTime: 8,
    correctStandImage: fullViewCorrectImage,
    degreeText: 'Please set your phone on 90Deg',
    hasDegree: true,

}
const sideCameraOptions: bodyMeasurementCameraOptions = {
    title: 'Side Pose',
    degreeAudioSrc: degreeAudioSrc,
    captureStartTime: 8,
    timerAudioSrc: timerAudioSrc,
    correctStandAudioSrc: sideViewCorrectAudioSrc,
    timerCount: 7,
    correctStandImage: sideViewCorrectImage,
    degreeText: 'Please set your phone on 90 Deg',
    hasDegree: true,

}
const BodyMeasurementCamera = () => {

    const [firstPhoto, setFirstPhoto] = useState<string | null>(null)
    const [secondPhoto, setSecondPhoto] = useState<string | null>(null)
    const [continueWithoutAccess, setContinueWithoutAccess] = useState(false)
    const measurementContext = useContext(MeasurementContext)
    const [currentType, setCurrentType] = useState("front")
    const [currentOptions, setCurrentOptions] = useState<bodyMeasurementCameraOptions>(frontCameraOptions)
    const webcamRef = React.useRef<Webcam>(null);
    const [isLoading, setLoading] = useState(true);
    const [cameraError, setCameraError] = useState<null | string>(null);
    const [showImage, setShowImage] = useState(false);
    const [isCorrectStand, setIsCorrectStand] = useState(false);
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [showTimer, setShowTimer] = useState(false);
    const [showDegree, setShowDegree] = useState(false);
    const {isPermissionGranted, requestPermission, isSupported, orientation} = useDeviceOrientation();
    const [showCaptureButton,setShowCaptureButton]=useState(!isPermissionGranted)
    const [capturing,setCapturing]=useState(false);
    const [accessLoading,setAccessLoading]=useState(false)
    const navigate = useNavigate();
    useEffect(()=>{
        setAccessLoading(true)
        requestPermission().then(response => {
            setAccessLoading(false)
            if (response === undefined) {

            }

        });
    },[])


    useEffect(() => {
        if (isCorrectStand) {
            setShowImage(true);

            const id = setTimeout(() => {
                //timer start counting
                measurementContext.handlePlaySound(0);

                setShowTimer(true);
            }, currentOptions.captureStartTime * 1000);
            setTimeoutId(id)
        } else {
            measurementContext.handlePlaySound(3);
            setShowTimer(false);
            setShowImage(false);
            if (timeoutId) {

                clearTimeout(timeoutId);
                setTimeoutId(null)
            }
        }
    }, [isCorrectStand, currentType]);

    useEffect(()=>{
        if(firstPhoto){
            setTimeout(()=>{
                setCapturing(false)
            },300)
        }
    },[firstPhoto])
    useEffect(()=>{
        if(secondPhoto){
            measurementContext.handleMeasurement(firstPhoto, secondPhoto)
        }
    },[secondPhoto])
    const handleOnCorrectStand = () => {

        setIsCorrectStand(true)

    }
    const handleOnWrongStand = () => {

        setIsCorrectStand(false)
    }

    const handleCapture =() => {
            setCapturing(true)
            if (webcamRef && webcamRef.current) {
                const img = webcamRef.current?.getScreenshot() || '';
                if (currentType === "front") {
                    setCurrentType("side")
                    setCurrentOptions(sideCameraOptions);
                    setShowTimer(false)
                    if(img){
                        setFirstPhoto(img)

                    }

                } else {
                    setSecondPhoto(img)

                }

            }

        }

    const handleContinueWithout = () => {
        setShowCaptureButton(true)
        setContinueWithoutAccess(true)
    }
    const handleCaptureButton=()=>{
        setIsCorrectStand(true);
        setShowCaptureButton(false)
    }

    if(capturing){
        return <Loading />
    }
    if (isSupported && !isPermissionGranted && !continueWithoutAccess) {
        return <div
            className="p-5 flex justify-center h-screen w-full   relative bg-white fixed top-0  z-1000 items-center flex-col mt-2">
            {!accessLoading && <>
                <h1 className="font-bold text-lg text-center ">
                    Permission denied
                </h1>
                <p className="text-sm text-center ">Enable motion and orientation from setting or continue without access</p>

                <div className="grid grid-cols-2 w-full  gap-2">
                    <Button onClick={handleContinueWithout} type="primary">continue</Button>
                    <Button onClick={() => navigate(-1)} type="default">back</Button>
                </div>
            </>}
        </div>
    }
    return <div>

        {isLoading && <div className="flex bg-white w-full items-center h-screen flex justify-center items-center justify-center"><Spin/></div>}
        {!isSupported && !isLoading && !cameraError &&
            <div className="measurement-capture-wrapper">
                <button onClick={handleOnCorrectStand} className="measurement-capture-button"/>
            </div>}
        {!isLoading&& <div className="disabled-deg-title"><span className="p-2 inline-block px-4 rounded-full bg-black bg-opacity-80">{currentOptions.title}</span></div>}
        <div className={`device  relative justify-center bg-black items-start ${isLoading || cameraError ? 'hidden' : 'flex'}`}>
            {currentOptions.hasDegree && showDegree &&isPermissionGranted &&
                <StandMobileDegree  isPermissionGranted={isPermissionGranted} isSupported={isSupported} orientation={orientation} type={currentType} onSuccess={handleOnCorrectStand} message={currentOptions.degreeText} onError={handleOnWrongStand}/>}

            <Webcam audio={false}
                    className="camera"
                    screenshotFormat="image/jpeg"
                    mirrored={FACING_MODE_USER === "user"}
                    ref={webcamRef}
                    videoConstraints={{

                        aspectRatio: 4 / 2,
                        ...videoConstraints,
                        facingMode: FACING_MODE_USER,
                    }}
                    onUserMedia={(stream) => {
                        setCameraError(null)
                        setLoading(false);
                        setShowDegree(true)
                        measurementContext.handleCameraErrorMessage(null)
                        measurementContext.handleCameraIsReady(true)
                    }}
                    onUserMediaError={(error) => {
                        measurementContext.handleCameraAccess(false)
                        if (error.toString().includes('dismissed')) {

                            measurementContext.handleCameraErrorMessage('Camera access is not enabled. Check camera access or choose photo from gallery')
                            setLoading(false)
                        } else if (error.toString().includes('denied')) {
                            measurementContext.handleCameraErrorMessage('You do not have camera access. Check camera access or choose photo from gallery')
                            setLoading(false)
                        } else if (error.toString().includes('Requested device not found')) {
                            measurementContext.handleCameraErrorMessage('No camera found,  choose photo from gallery')
                            setLoading(false)
                        } else {
                            measurementContext.handleCameraErrorMessage('No camera found,  choose photo from gallery')
                        }
                    }}
            />


            {!isLoading && !cameraError && <>
                <img src={currentOptions.correctStandImage} className={`stand-image z-50 absolute top-1/2 -translate-y-1/2 ${showImage ? '' : 'hidden'}`} alt=""/>
            </>}
            {showCaptureButton&&!isPermissionGranted && <div className="rounded-full  flex justify-center items-center text-white p-5 bg-primary z-50 absolute bottom-10" onClick={handleCaptureButton}><TbCapture className="text-xl"/></div>}
            {showTimer && <CountTimer count={currentOptions.timerCount} handleCapture={handleCapture}/>}


        </div>

    </div>
}
const CountTimer = ({count, handleCapture}: any) => {

    const [timer, setTimer] = useState(count)

    useEffect(() => {
        const timeout = setTimeout(() => {
            handleCapture()
        }, count * 1000)
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    useEffect(() => {

        const interval = setInterval(() => {

            if (timer >= 1) {
                setTimer(timer - 1);
                if (timer - 1 === 0) {

                }
            }

        }, 1000);

        //Clearing the interval
        return () => {

            clearInterval(interval)
        };
    }, [timer]);
    if (timer === 0) {
        return <></>
    }
    return <>
        <div className="timer">

            <AiOutlineLoading3Quarters className="z-40 drop-shadows-sm absolute   animate-spin  w-full h-full"/>
            <span className="absolute  drop-shadows-sm text-4xl font-bold z-50">{timer}</span>
        </div>
    </>
}

export default BodyMeasurementCamera;