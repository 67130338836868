import TopTitle from "../TopTitle";
import type { FormProps } from 'antd';
import {Button, Divider, Form, Input, InputNumber} from 'antd';
import {useContext} from "react";
import DataContext from "../../utilities/contexts/sdk/dataContext";
import ContentWrapper from "./ContentWrapper";

type FieldType = {
    height:number,
};
interface Props{
    handleBack?:Function,
    hasBack:boolean,
}

const GetHeight=({hasBack,handleBack}:Props)=>{
    const [form] = Form.useForm();
    const dataContext=useContext(DataContext)
    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        dataContext.handleHeight(values.height)
    };


    const handleChange = (value: string | number | null) => {
        const reg = /^\d*$/;
        if (typeof value === 'string') {
            if (reg.test(value)) {
                form.setFieldsValue({ number: value });
            }
        } else if (typeof value === 'number') {
            form.setFieldsValue({ number: value });
        }
    };
    const handleSubmit=()=>{
        form.submit();
    }
    const handlePrev=()=>{
        if(typeof handleBack =="function"){
            handleBack()
        }
    }

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return <div className="w-full md:w-6/12 lg:w-4/12 xl:w-3/12 mx-auto ">
                <TopTitle title="INFORMATION"  disableBack={true}/>
        <ContentWrapper>
            <Form
                className=" mx-5   p-5  rounded-lg  "
                name="form"
                form={form}
                requiredMark={false}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
                {dataContext.frontPoseImage&&dataContext.sidePoseImage &&      <div className="w-full mb-5 grid grid-cols-2 gap-5">
                    <img className="rounded-lg "  src={dataContext.frontPoseImage} alt=""/>
                    <img className="rounded-lg " src={dataContext.sidePoseImage} alt=""/>
                </div>}
                <Form.Item<FieldType>
                    label="Enter Your Height"
                    name="height"
                    rules={[{ required: true, message: 'Please input your height!' }]}
                >
                    <InputNumber onChange={handleChange} size="large" className="w-full" addonAfter="cm" />
                </Form.Item>
                <Divider/>

            </Form>

        </ContentWrapper>
        <div className=" w-full left-0 flex justify-center fixed bottom-0 px-4 py-3 bg-white bottom-0 shadow">
            <div className={`w-full md:w-full lg:w-5/12  xl:w-4/12  grid  ${hasBack? 'grid-cols-2':'grid-cols-1'}`}>
                {hasBack && <Button onClick={handlePrev}  type="link"   size="large" className=" w-full">Back</Button>}

                <Button type="primary" onClick={handleSubmit} size="large" className="btn-primary w-full">SUBMIT</Button>
            </div>

        </div>



            </div>
}
export default GetHeight;