import React, {useContext, useState} from "react";
import {ArrowLeftOutlined, DeleteOutlined} from "@ant-design/icons";
import AppContext from "../utilities/contexts/appContext";
import {useNavigate} from "react-router-dom";
import {FiLogOut} from "react-icons/fi";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";

interface Props{
    title:string,
    logout?:boolean
    disableBack?:boolean
}
const TopTitle=(props:Props)=>{
    const {t}=useTranslation()
    const appContext=useContext(AppContext);
    const [modal, contextHolder] = Modal.useModal();
    const navigate=useNavigate();
    const handleBack=()=>{
        if(appContext.step===0){
            navigate(-1)
        }else {
            appContext.handleStep(appContext.step-1)
        }
    }
    const confirmLogout = () => {

        modal.confirm({

            content:t('logout.title'),
            type: "error",
            okText: t('logout.okButton'),
            cancelButtonProps:{
                type:"text"
            },
            cancelText: t('logout.cancelButton'),
            icon:<></>,
            centered: true,
            okButtonProps: {
                danger: true,
                type: "primary"
            },
            onOk: () => {
                appContext.handleLogout()
            }
        })
    }
    return  <div className="w-full ltr-grid bg-white px-5 flex items-center justify-between z-40 fixed top-0 lg:hidden shadow-sm py-5 ">
        {contextHolder}

        {props.disableBack? <div className="w-[14px] h-[14px]"/>:<ArrowLeftOutlined onClick={handleBack}/> }
        <h1 className="   text-[17px] uppercase text-gray-600 text-center font-bold">
            {props.title}
        </h1>
       {props.logout ?     <FiLogOut className="text-lg text-red-400 " onClick={confirmLogout}/> :<div className="w-[14px] h-[14px]"/>}


    </div>
}
export default TopTitle;