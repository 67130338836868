import {Button} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";

interface Props{
    loading:boolean,
    canBack:boolean,
    onContinue:Function,

}
const MotionAccessCard=(props:Props)=>{
    const {loading,canBack,onContinue}=props;
    const navigate=useNavigate();
    const handleContinue=()=> {
        onContinue()
    }
    return <div
        className="p-5 flex justify-center h-screen w-full   relative bg-white fixed top-0  z-1000 items-center flex-col mt-2">
        {!loading && <>
            <h1 className="font-bold text-lg text-center ">
                Permission denied
            </h1>
            <p className="text-sm text-center ">Enable motion and orientation from setting or continue without
                access</p>

            <div className={`grid  w-full  gap-2 ${canBack ? "grid-cols-2":"grid-cols-1"}`}>
                <Button onClick={handleContinue} type="primary">continue</Button>
                {canBack && <Button onClick={() => navigate(-1)} type="default">back</Button>}
            </div></>}
    </div>
}

export default MotionAccessCard;