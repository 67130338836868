import Webcam from "react-webcam";
import React, {useContext, useEffect, useState} from "react";
import StandMobileDegree from "../StandMobileDegree";
import useDeviceOrientation from "../../utilities/hooks/useDeviceOrientation";
import bodyMeasurementCameraOptions from "../../utilities/types/bodyMeasurementCameraOptions";
import degreeAudioSrc from "../../sounds/90Degrees.wav";
import timerAudioSrc from "../../sounds/Timer.mp3";
import fullViewCorrectAudioSrc from "../../sounds/fullView.wav";
import fullViewCorrectImage from "../../images/measurement/full_side.png";
import sideViewCorrectAudioSrc from "../../sounds/sideView.wav";
import sideViewCorrectImage from "../../images/measurement/half_side.png";
import Loading from "../Loading";
import MeasurementCameraContext from "../../utilities/contexts/measurementCameraContext";
import Timer from "../measurementCamera/Timer";
import CaptureButton from "../measurementCamera/CaptureButton";
import MotionAccessCard from "../measurementCamera/MotionAccessCard";

const FACING_MODE_USER = "user";
const videoConstraints = {
    facingMode: FACING_MODE_USER
};

const frontCameraOptions: bodyMeasurementCameraOptions = {
    title: 'Front Pose',
    degreeAudioSrc: degreeAudioSrc,
    timerAudioSrc: timerAudioSrc,
    correctStandAudioSrc: fullViewCorrectAudioSrc,
    timerCount: 7,
    captureStartTime: 8,
    correctStandImage: fullViewCorrectImage,
    degreeText: 'Please set your phone on 90Deg',
    hasDegree: true,

}
const sideCameraOptions: bodyMeasurementCameraOptions = {
    title: 'Side Pose',
    degreeAudioSrc: degreeAudioSrc,
    captureStartTime: 8,
    timerAudioSrc: timerAudioSrc,
    correctStandAudioSrc: sideViewCorrectAudioSrc,
    timerCount: 7,
    correctStandImage: sideViewCorrectImage,
    degreeText: 'Please set your phone on 90 Deg',
    hasDegree: true,

}
interface Props {
    onComplete: Function,
    onError:Function,
}

const BodyMeasurementCamera = ({onComplete,onError}: Props) => {
    const CameraSetting = {aspectRatio: 4 / 2, ...videoConstraints};
    const [frontPosePhoto, setFrontPosePhoto] = useState<string | null>(null)
    const [sidePosePhoto, setSidePosePhoto] = useState<string | null>(null)
    const [continueWithoutAccess, setContinueWithoutAccess] = useState(false)
    const measurementCameraContext = useContext(MeasurementCameraContext)
    const [currentType, setCurrentType] = useState("front")
    const [currentOptions, setCurrentOptions] = useState<bodyMeasurementCameraOptions>(frontCameraOptions)
    const webcamRef = React.useRef<Webcam>(null);
    const [isLoading, setLoading] = useState(true);
    const [cameraError, setCameraError] = useState<null | string>(null);
    const [showImage, setShowImage] = useState(false);
    const [isCorrectStand, setIsCorrectStand] = useState(false);
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [showTimer, setShowTimer] = useState(false);
    const [showDegree, setShowDegree] = useState(false);
    const {isPermissionGranted, requestPermission, isSupported, orientation} = useDeviceOrientation();
    const [showCaptureButton, setShowCaptureButton] = useState(!isPermissionGranted)
    const [capturing, setCapturing] = useState(false);
    const [takingPhoto,setTakingPhoto]=useState(false)
    const [accessLoading, setAccessLoading] = useState(false);

    useEffect(() => {
        setAccessLoading(true)
        requestPermission().then(response => {
            setAccessLoading(false)
            if (response === undefined) {

            }

        });
    }, [])
    useEffect(() => {
        if (isCorrectStand) {
            setShowImage(true);
            const id = setTimeout(() => {
                //timer start counting
                measurementCameraContext.handlePlaySound(0);
                setShowTimer(true);
            }, currentOptions.captureStartTime * 1000);
            setTimeoutId(id)
        } else {
            measurementCameraContext.handlePlaySound(3);
            setShowTimer(false);
            setShowImage(false);
            if (timeoutId) {

                clearTimeout(timeoutId);
                setTimeoutId(null)
            }
        }
    }, [isCorrectStand, currentType]);
    useEffect(() => {
        if (frontPosePhoto) {
            setTimeout(() => {
                setCapturing(false)
            }, 300)
        }
    }, [frontPosePhoto])
    useEffect(() => {
        if (sidePosePhoto) {
            if (frontPosePhoto && sidePosePhoto) {

                onComplete(frontPosePhoto, sidePosePhoto)
            }
        }
    }, [sidePosePhoto])
    const handleOnCorrectStand = () => {
        setIsCorrectStand(true)

    }
    const handleOnWrongStand = () => {

        setIsCorrectStand(false)
    }

    const handleCapture = () => {
        setCapturing(true)
        if (webcamRef && webcamRef.current) {
            const img = webcamRef.current?.getScreenshot() || '';
            if (currentType === "front") {
                setCurrentType("side")
                setCurrentOptions(sideCameraOptions);
                setShowTimer(false)
                if (img) {
                    setFrontPosePhoto(img)

                }

            } else {
                setSidePosePhoto(img)

            }

        }

    }

    const handleContinueWithout = () => {
        setShowCaptureButton(true)
        setContinueWithoutAccess(true)
    }
    const handleMediaError = (error: any) => {
        console.log(error)
        if (error.toString().includes('dismissed')) {

            onError('Camera access is not enabled. Check camera access or choose photo from gallery')
        } else if (error.toString().includes('denied')) {
            onError('You do not have camera access. Check camera access or choose photo from gallery')
        } else if (error.toString().includes('Requested device not found')) {
            onError('No camera found,  choose photo from gallery')
        } else {
            onError('No camera found,  choose photo from gallery')
        }
        setLoading(false)
    }
    const onMediaStream = (stream: MediaStream) => {
        setCameraError(null)
        setLoading(false);
        setShowDegree(true)
        console.log(stream)
        measurementCameraContext.handleCameraIsReady(true)
    }
    const handleTakePhoto=()=>{
     setTakingPhoto(true);
     setIsCorrectStand(true)
    }
    if (capturing) {
        return <Loading/>
    }
    if (isSupported && !isPermissionGranted && !continueWithoutAccess) {
        return <MotionAccessCard loading={accessLoading} onContinue={handleContinueWithout} canBack={false}/>
    }
    return <div>
        {!takingPhoto &&      <div className="measurement-capture-wrapper hidden lg:block">
            <button onClick={handleTakePhoto} className="measurement-capture-button"/>
        </div>}
        {!isSupported && !cameraError && <></>}
        <div className="disabled-deg-title"><span
            className="p-2 inline-block px-4 rounded-full bg-black bg-opacity-80">{currentOptions.title}</span></div>
        {isLoading && <Loading/>}
        <div
            className={`device  relative justify-center bg-black items-start ${isLoading || cameraError ? 'hidden' : 'flex'}`}>
            {currentOptions.hasDegree && showDegree && isPermissionGranted &&
                <StandMobileDegree isPermissionGranted={isPermissionGranted} isSupported={isSupported}
                                   orientation={orientation} type={currentType} onSuccess={handleOnCorrectStand}
                                   message={currentOptions.degreeText} onError={handleOnWrongStand}/>}
            <Webcam audio={false} className="camera" screenshotFormat="image/jpeg"
                    mirrored={FACING_MODE_USER === "user"} ref={webcamRef} videoConstraints={CameraSetting}
                    onUserMedia={onMediaStream} onUserMediaError={(error) => {
                         console.log(error)
                         handleMediaError(error)
            }}/>
            {!cameraError && <img src={currentOptions.correctStandImage}
                                  className={`stand-image z-50 absolute top-1/2 -translate-y-1/2 ${showImage ? '' : 'hidden'}`}
                                  alt=""/>}
            {showCaptureButton && !isPermissionGranted && <CaptureButton onClick={handleCapture}/>}
            {showTimer && <Timer count={currentOptions.timerCount} onComplete={handleCapture}/>}


        </div>

    </div>
}


export default BodyMeasurementCamera;