import React from "react";
import {Button} from "antd";
import NotFoundImage from "../../images/notfound.svg"
import {Link} from "react-router-dom";
const NotFound=()=>{

    return <div className="h-screen flex justify-center items-center flex-col">
        <img src={NotFoundImage} className=" w-[200px] lg:w-[300px] h-auto" alt=""/>
        <h1 className="mt-4 text-2xl">Page not found</h1>
        <Link  to={'/en/dashboard'}><Button type="primary" className="mt-5 ">Back to home</Button></Link>
    </div>
}
export default NotFound;