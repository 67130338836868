import {Spin} from "antd";
interface Props {
    message?:string
}
const Loading=(props:Props)=>{

    return <div className="w-full bg-p  flex-col items-center  h-screen flex justify-center top-0 fixed z-1000 left-0 ">
        {props.message && <span>{props.message}</span>}
        <Spin  size="large"/>

    </div>
}
export default Loading;