import React, {useContext, useEffect, useRef, useState} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {DeviceOrientationState} from "../utilities/hooks/useDeviceOrientation";

import MeasurementCameraContext from "../utilities/contexts/measurementCameraContext";

interface Props {
    onSuccess: Function,
    onError: Function,
    onNotSupport?: Function,
    message?: string | null,
    type: string,
    orientation: DeviceOrientationState,
    isPermissionGranted: boolean,
    isSupported: boolean,

}

const defaultMessage = "Place your mobile on the table at 90 degrees";
const StandMobileDegree = (props: Props) => {
    const {
        type,
        onSuccess,
        onError,
        onNotSupport,
        orientation,
        isSupported,
        isPermissionGranted,
        message = defaultMessage
    } = props;
    const [hasError, setHasError] = useState(false)
    const degreeWrapperRef = useRef<HTMLDivElement | null>(null);
    const switcherRef = useRef<HTMLDivElement | null>(null);
    const [showMessage, setShowMessage] = useState(true)
    const minDegree = 80;
    const maxDegree = 100;
    const [status, setStatus] = useState("")
    const measurementContext = useContext(MeasurementCameraContext)

    useEffect(() => {
        switch (status) {
            case "error": {
                console.log('error')
                measurementContext.handlePlaySound(3)
                break
            }
            case "success": {
                console.log("success")
                if (type === "front") {
                    measurementContext.handlePlaySound(1)
                } else {
                    measurementContext.handlePlaySound(2)
                }
                break
            }

        }
    }, [status])
    useEffect(() => {
        if (isPermissionGranted && isSupported) {
            handleOrientation(orientation)
        }

    }, [orientation, isPermissionGranted])
    const handleOrientation = (event: DeviceOrientationState) => {
        const beta = event.beta;
        if (beta) {
            setHasError(false)
            if (switcherRef.current) {
                if (beta > maxDegree || beta < minDegree) {
                    switcherRef.current.style.top = beta / 2 + '%';
                    switcherRef.current.style.backgroundColor = '#d51e3e';
                    setShowMessage(true);
                    onError();
                    setStatus('error')

                } else {
                    setShowMessage(false)
                    switcherRef.current.style.backgroundColor = '#4ab764';
                    switcherRef.current.style.top = beta / 2 + '%';
                    onSuccess();
                    setStatus('success')

                }

            }
        } else {
            if (typeof onNotSupport === "function") {
                onNotSupport();
            }
            setHasError(true)
            setStatus('error')
        }


    };
    if (hasError) {
        return <></>
    }
    return <div ref={degreeWrapperRef} className={`h-screen relative overflow-hidden degree-wrapper `}>

        {showMessage && <div
            className=" z-degree  fixed top-1/2 transform -translate-y-1/2 w-full text-xl   px-5  flex justify-center items-center   ">
            <p className=" rounded-xl font-bold py-2 text-center font-bold drop-shadow-2xl text-white px-5">{message}</p>
        </div>}


        <div
            className="grid grid-cols-1 m-1  camera-degree-controller flex justify-center items-center   bg-white bg-opacity-60  p-2 rounded-full gap-2">

            <div ref={switcherRef} className="degree-switcher">
                {showMessage ? <CloseOutlined className="text-white"/> : <CheckOutlined className="text-white"/>}

            </div>
            <div className="  h-4 w-4 opacity-50 rounded-full  bg-red-500"/>
            <div className="  h-4 w-4 opacity-50 rounded-full  bg-red-500"/>
            <div className="  h-4 w-4 opacity-50 rounded-full  bg-green-500"/>
            <div className="  h-4 w-4 opacity-50 rounded-full  bg-red-500"/>
            <div className="  h-4 w-4 opacity-50 rounded-full  bg-red-500"/>


        </div>

    </div>
}
export default StandMobileDegree;