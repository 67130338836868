import React, {useState, Suspense} from 'react';
import 'antd/dist/reset.css';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import AppLayout from "./components/Layout";
import {ConfigProvider} from "antd";
import AppContext from "./utilities/contexts/appContext";
import Loading from "./components/Loading";
import {GoogleOAuthProvider} from "@react-oauth/google";
import Guest from "./components/pages/guest/Guest"
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import './locales/i18n';
import {DirectionType} from "antd/es/config-provider";
/*import Test from "./components/pages/test";*/
import SizeMeasurement from "./components/pages/SizeMeasurement";
import Gate from "./components/pages/sdk/Gate";
import SdkSizeMeasurement from "./components/pages/sdk/SizeMeasurement";
import SdkLayout from "./components/sdk/SdkLayout";
import SdkAccessDenied from "./components/pages/sdk/SdkAccessDenied";
import SdkNotFound from "./components/pages/sdk/SdkNotFound";
import NotFound from "./components/pages/NotFound";

i18next.use(initReactI18next).init({
    lng: 'en', // Set the default language here
    fallbackLng: 'en', // Fallback language if translation not found
    interpolation: {
        escapeValue: false // React already escapes strings, so no need to escape again
    }
});
function App() {

    /*const SearchEngine = React.lazy(() => import('./components/pages/SearchEngine'));*/
    const Login = React.lazy(() => import('./components/pages/Login'));
    const Register = React.lazy(() => import('./components/pages/Register'));
    const AddCharacteristic = React.lazy(() => import('./components/pages/AddCharacteristic'));
    const Profile = React.lazy(() => import('./components/pages/Profile'));
    const Dashboard = React.lazy(() => import('./components/pages/Dashboard'));
    const PersonList = React.lazy(() => import('./components/pages/person/PersonList'));
    const PersonProfile = React.lazy(() => import('./components/pages/person/PersonProfile'));
    const AddPerson = React.lazy(() => import('./components/pages/person/AddPerson'));
    const EditPerson = React.lazy(() => import('./components/pages/person/EditPerson'));
    const EditProfile = React.lazy(() => import('./components/pages/EditProfile'));
    const ServiceList = React.lazy(() => import('./components/pages/services/ServiceList'));
    const ResetPassword = React.lazy(() => import('./components/pages/ResetPassword'));
    const [step,setStep]=useState(0);
    const [token,setToken]=useState<string|null>(localStorage.getItem('token'))
    const [isLoading, setLoading] = useState(false);
    const [capturing,setCapturing]=useState(false);
    const [direction,setDirection]=useState<DirectionType>("ltr");
    const [user,setUser]=useState<any>(null);
    const [lang,setLang]=useState('en');

    const handleLang=(lang:string)=>{
        setLang(lang)
    }

    const handleLogin=(token:string)=>{
        setToken(token);
        localStorage.setItem('token',token)
    }
    const handleLogout=()=>{
                    setToken(null);
                    localStorage.removeItem('token');
                    setUser(null);
    }
    const handleStep=(num:number)=>{
        setStep(num)
    }
    const handleCapturing=(value:boolean)=>{
        setCapturing(value)
    }
    const handleLoading = (loading: boolean) => {
        setLoading(loading)
    }
    const handleDirection = (direction: DirectionType) => {
        setDirection(direction);

    }
    const handleUser=(user:any)=>{
        setUser(user);

    }


/*    555255542706-6r2amf101t9fi03apkvpignu3dgf6caj.apps.googleusercontent.com*/
    return ( <GoogleOAuthProvider  clientId="555255542706-6r2amf101t9fi03apkvpignu3dgf6caj.apps.googleusercontent.com">

        <AppContext.Provider value={{
        handleLoading: handleLoading,
        handleUser:handleUser,
        user:user,
        isLoading: isLoading,
        step:step,
        capturing:capturing,
        handleCapturing:handleCapturing,
        handleStep:handleStep,
        handleDirection:handleDirection,
        direction:direction,
        token:token,
        lang:lang,
        handleLang:handleLang,
        handleLogin:handleLogin,
        handleLogout:handleLogout,}}>



        <ConfigProvider direction={direction}
                        theme={{
                            token: {
                                colorPrimary: '#474BCF',
                                fontFamily: lang==='fa'?'iranYekan':'Roboto',

                            },
                        }}
        >
            {isLoading && <Loading/>}
            <BrowserRouter>
                <Routes  >
                    <Route index element={(!!user && !!token) ? <Navigate to={`/${i18next.language}/dashboard/`} replace={true}/> :<Navigate to={`/${i18next.language}/guest`}/>}/>
                    <Route path="auth/password-reset" element={<Suspense fallback={<Loading />}><ResetPassword/></Suspense>}/>
                    <Route   element={<AppLayout/>} path=":lang"  >
                        <Route index element={(!!user && !!token) ? <Navigate  to={`/${i18next.language}dashboard/`}/> :<Navigate to={`/${i18next.language}/guest`}/>}/>
                        <Route path="login" element={ <Suspense fallback={<Loading />}>
                            <Login/></Suspense>}/>
                        <Route path="auth/password-reset" element={ <Suspense fallback={<Loading />}><ResetPassword/></Suspense>}/>
                        <Route path="register" element={<Suspense fallback={<Loading />}><Register/></Suspense>}/>
                        <Route path="setInformation" element={<Suspense fallback={<Loading />}><AddCharacteristic/></Suspense>}/>
                        <Route path="dashboard" element={<Suspense fallback={<Loading />}><Dashboard/></Suspense>}>
                            <Route path="persons" >
                                <Route index element={<Suspense fallback={<Loading />}><PersonList/></Suspense>}/>
                                <Route path=":personId" element={<Suspense fallback={<Loading />}><PersonProfile/></Suspense>} />
                                <Route path=":personId/edit" element={<Suspense fallback={<Loading />}><EditPerson/></Suspense>} />
                                <Route path=":personId/body-measurement" element={<Suspense fallback={<Loading />}><SizeMeasurement/></Suspense>} />
                              {/*  <Route path=":personId/visual-clothe-search" element={<Suspense fallback={<Loading />}><SearchEngine/></Suspense>} />*/}
                                <Route path="addPerson" element={<Suspense fallback={<Loading />}><AddPerson/></Suspense>}/>
                            </Route>
                            <Route path="services">
                                <Route index element={<Suspense fallback={<Loading />}><ServiceList/></Suspense>}/>
                                <Route path="body-measurement"  element={<Suspense fallback={<Loading />}><SizeMeasurement/></Suspense>}/>
                           {/*     <Route path="visual-clothe-search" element={<Suspense fallback={<Loading />}><SearchEngine/></Suspense>}/>*/}
                            </Route>
                            <Route path="editProfile" element={<Suspense fallback={<Loading />}><EditProfile/></Suspense>}/>
                            <Route index element={<Suspense fallback={<Loading />}><Profile/></Suspense>}/>
                        </Route>
                   {/*     <Route path="test" element={<Test/>}/>*/}
                       <Route path="guest" >
                           <Route index element={<Guest/>}/>
                           <Route path="body-measurement"  element={token ? <Navigate to={'/dashboard'}  /> :<Suspense fallback={<Loading />}><SizeMeasurement demo={true}/></Suspense> }/>
                        {/*   <Route path="visual-clothe-search" element={<SearchEngine demo={true}/>}/>*/}
                       </Route>
                     {/*   <Route path="test" element={<Suspense fallback={<Loading />}><CameraExample/></Suspense>}/>*/}


                    </Route>
                    <Route path='sdk' element={<SdkLayout/>}>
                        <Route index element={<Gate/>}/>
                        <Route path="sizeMeasurement" element={<SdkSizeMeasurement/>}/>
                        <Route path="accessDenied" element={<SdkAccessDenied/>}/>
                        <Route path="*" element={<SdkNotFound/>}/>
                    </Route>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    </AppContext.Provider>
    </GoogleOAuthProvider>);
}

export default App;
