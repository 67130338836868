import React from "react";

interface dataContextInterface {
    handleHeight: Function,
    handleFrontPoseImage: Function,
    handleTakePhoto: Function,
    handleSidePoseImage: Function,
    height: number | null,
    sidePoseImage: string | null,
    frontPoseImage: string | null,
    isCapturing:boolean,
    handleCapturing:Function,
}

const DataContext = React.createContext<dataContextInterface>({
    frontPoseImage: null,
    sidePoseImage: null,
    handleCapturing:()=>{

    },
    height: null,
    isCapturing:false,
    handleFrontPoseImage: () => {

    },
    handleTakePhoto:()=>{

    },
    handleSidePoseImage: () => {

    },
    handleHeight: () => {

    }

});
export default DataContext;