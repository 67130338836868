import React, {useEffect, useState} from "react";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
interface Props{
    count:number,
    onComplete:Function,
}
const Timer = (props:Props) => {
    const {count,onComplete}=props;
    const [timer, setTimer] = useState(count)

    useEffect(() => {
        const timeout = setTimeout(() => {
            onComplete()
        }, count * 1000)
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    useEffect(() => {

        const interval = setInterval(() => {

            if (timer >= 1) {
                setTimer(timer - 1);
                if (timer - 1 === 0) {

                }
            }

        }, 1000);

        //Clearing the interval
        return () => {

            clearInterval(interval)
        };
    }, [timer]);
    if (timer === 0) {
        return <></>
    }


    return <>
        <div className="timer">
            <AiOutlineLoading3Quarters className="z-40 drop-shadows-sm absolute   animate-spin  w-full h-full"/>
            <span className="absolute  drop-shadows-sm text-4xl font-bold z-50">{timer}</span>
        </div>
    </>
}
export default Timer;