import {TbCapture} from "react-icons/tb";
import React from "react";

interface Props {
    onClick:Function,
}

const CaptureButton = (props:Props) => {
    const {onClick}=props;
    const handleClick=()=>{
        onClick()
    }
    return <div
        className="rounded-full  flex justify-center items-center text-white p-5 bg-primary z-50 absolute bottom-10"
        onClick={handleClick}><TbCapture className="text-xl"/></div>
}
export default CaptureButton;